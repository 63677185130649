import React, { useState } from 'react';
import './NavBar.css';
import { Link } from "react-router-dom";
import { SocialIcon } from 'react-social-icons';
import {isMobile} from 'react-device-detect';
import Hamburger from 'hamburger-react'


const NavBar = ({ setDisplay, currentPage }) => {

  const sidebarItems = [
    { path: '/', text: 'Home'},
    { path: '/projects', text: 'Projects' },
    // { path: '/blog', text: 'Blog' },
    { path: '/contact', text: 'Contact'}
  ];

  const handleClick = (context) => {
    if(isMobile) {
      setDisplay(false);
    }
  }

  const renderSidebarItems = (setDisplay) => {
    return sidebarItems.map((item) => {
      return (
        <div className='d-flex justify-content-center item'>
          <Link to={item.path} onClick={(context) => handleClick(context)} className="d-flex pb-3 mb-md-0 me-md-auto text-decoration-none text-light link-hover">
            <span className={`fs-5 d-sm-inline mt-4 link-fonts ${currentPage === item.text.toLowerCase() ? "bold-text-nav" : ""}`}>{item.text}</span>
          </Link>
        </div>
      )
    })
  }

  return (
    <div className='d-flex flex-column fixed-top col-md-3 col-xl-2 px-sm-2 px-0 text-light navbar-content min-vh-100 justify-content-between'>
      <div className="d-flex flex-column px-3 pt-2 text-white">
        {renderSidebarItems()}
        {isMobile ? <div className="d-flex socialIcons p-2 justify-content-center">
        <SocialIcon className="m-2" target="_blank" url="https://www.linkedin.com/in/paul-bekaert-b069491b8/" />
        <SocialIcon className="m-2" target="_blank" url="https://github.com/paulbeka" bgColor="white" />
      </div>: <></>}
      </div>
      {isMobile ? <div style={{"position":"fixed", "margin-top":"1em", "margin-left":"0.5em", "zIndex":"99"}}><Hamburger toggled={true} toggle={setDisplay} aria-label="sidebar close button" /></div> :<></>}
      {isMobile ? <></> : <div className="d-flex socialIcons p-2 justify-content-center">
        <SocialIcon className="m-2" target="_blank" url="https://www.linkedin.com/in/paul-bekaert-b069491b8/" />
        <SocialIcon className="m-2" target="_blank" url="https://github.com/paulbeka" bgColor="white" />
      </div>}
      
    </div>
  )
}

export default NavBar;