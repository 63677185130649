import React from 'react';
import './CSS/ProjectsPage.css';
import { useTrail, animated } from "react-spring";
import {BrowserView, MobileView} from 'react-device-detect';
import { projects } from '../content/Projects.js'
import { Link, useLocation } from "react-router-dom";


const ProjectsPage = () => {

  const trail = useTrail(projects.length, {
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  const location = useLocation();

  const renderButtons = (item) => {
    if("link" in item && "blog" in item) {
      return (
        <div>
          <a style={{"color": "inherit"}} className="link-unstyled" href={item.link} target="_blank"><button className="button-21 m-1" role="button">Code Link</button></a>
          <Link style={{"color": "inherit"}} className="link-unstyled" to={`/blog/${item.blog}`} state={{ prevPath: location.pathname }}><button className="button-21 m-1" role="button">Blog Post Link</button></Link>
        </div>
      )
    } else if ("link" in item) {
      return <a style={{"color": "inherit"}} className="link-unstyled" href={item.link} target="_blank"><button className="button-21" role="button">Code Link</button></a>
    } else if ("blog" in item) {
      return <Link style={{"color": "inherit"}} className="link-unstyled" to={`/blog/${item.blog}`} state={{ prevPath: location.pathname }}><button className="button-21" role="button">Blog Post Link</button></Link>
    }
  }

  const renderProjectLeft = (item) => {
    return (
      <>
        <div className="d-flex projectItem">
          <img src={item.img} className="projectImageLeft" />
          <div className="leftText">
            <h1>{item.title}</h1>
            <p>{item.description}</p>            
            {renderButtons(item)}
          </div>
        </div>
      </>
    )
  }

  const renderProjectRight = (item) => {
    return (
      <>
        <div className="d-flex flex-row-reverse projectItem">
          <img src={item.img} className="projectImageRight" />
          <div className="rightText">
            <h1>{item.title}</h1>
            <p>{item.description}</p>
            {renderButtons(item)}
          </div>
        </div>
      </>
    )
  }

  const renderProjectMiddle = (item) => {
    return (<>
      <div className="">
        <img src={item.img} className="projectImageMiddle" />
        <div className="middleText">
          <h1>{item.title}</h1>
          <p>{item.description}</p>
          {renderButtons(item)}
        </div>
      </div>
    </>)
  }
  
  const renderProjects = (trail, mobile) => {
    if(mobile) {
      return trail.map((animation, i) => {
        return <animated.div style={animation}>{renderProjectMiddle(projects[i])}</animated.div>
      });
    } else {
      return trail.map((animation, i) => {
        if((i % 2) === 0) {
          return <animated.div style={animation}>{renderProjectLeft(projects[i])}</animated.div>
        } else {
          return <animated.div style={animation}>{renderProjectRight(projects[i])}</animated.div>
        }
      })
    }
  }

  return (<>
    <BrowserView>
      <div className="cv-container">
        <div className="title">
          <h1>Projects</h1>
          <hr />
        </div>
        {renderProjects(trail, false)}
      </div>
    </BrowserView>
    <MobileView>
      <div className="cv-container-mobile">
        <div className="title-mobile">
          <h1>Projects</h1>
          <hr />
        </div>
        {renderProjects(trail, true)}
      </div>
    </MobileView>
  </>)
}

export default ProjectsPage;