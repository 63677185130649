import React from 'react';
import './CSS/HomePage.css';
import profilePicture from '../images/me.webp';
import { IoIosArrowDown } from "react-icons/io"
import HomeLowerPage from './HomeLowerPage';
import {BrowserView, MobileView} from 'react-device-detect';

const HomePage = () => {
  return (
    <>
    <BrowserView>
    <div className="homeContent">
      <div className="homePage d-flex flex-column">
        <div className="homePageContent d-flex">
          <div className="d-flex align-items-center inside-content">
            <img src={profilePicture} alt="Paul Bekaert" className="profilePicture align-middle" />
            <div className="textContent">
              <h1 className="homeInfoTitle pl-1">PAUL BEKAERT</h1>
              <p className="homeInfoText pl-1">Fifth year (Master's faster route) Computer Science student at the University of Glasgow. 
              Previously interned at Goldman Sachs and JPMorgan. Part time tutor for Crimson Education. Aspiring
              business entrepreneur.</p>
              <a target="_blank" href={"/pdfs/cv.pdf"} rel="noreferrer"><button class="button-21">Resume</button></a>
            </div>
          </div>
        </div>
        <div className="downArrow mt-auto">
          <center>
            <a alt="Down Arrow for Bottom Page" style={{"color": "inherit"}} className="link-unstyled" href="#otherContent"><IoIosArrowDown style={{"width":"80px", "height": "auto"}} /></a>
          </center>
        </div>
      </div>
      <div id="otherContent" className="otherContent">
        {HomeLowerPage()}
      </div>
      
    </div>
    </BrowserView>
    <MobileView>
      <div className="homeContent">
        <div className="homePage d-flex flex-column">
           <div className="align-items-center inside-content">
              <img src={profilePicture} alt="Paul Bekaert" className="profilePicture mobile-align" />
              <div className="textContentMobile">
                <h1 className="homeInfoTitle pl-1">PAUL BEKAERT</h1>
                <p className="homeInfoText pl-1">Fifth year (Master's faster route) Computer Science student at the University of Glasgow. 
              Previously interned at Goldman Sachs and JPMorgan. Part time tutor for Crimson Education. Aspiring
              business entrepreneur.</p>
                <a target="_blank" href={"/pdfs/cv.pdf"} rel="noreferrer"><button class="button-21">Resume</button></a>
              </div>
              <br />
              <br />
              <div className="downArrow mt-auto">
                <center>
                  <a style={{"color": "inherit"}} className="link-unstyled" href="#otherContent"><IoIosArrowDown style={{"width":"80px", "height": "auto"}} /></a>
                </center>
              </div>
            </div>
          </div>
        <div id="otherContent" className="otherContent">
          {HomeLowerPage()}
        </div>
      </div>
    </MobileView>
    </>
  )
}

export default HomePage;