import React, { useEffect, useState } from 'react'
import './CSS/BlogPostBase.css'
import { useParams } from 'react-router-dom';
import { BsArrowLeftShort } from 'react-icons/bs'
import { Link, useLocation } from "react-router-dom";


const BlogPostBase = () => {


  const { blogName } = useParams();
  const location = useLocation()
  const [jsonData, setJsonData] = useState(null);

  const renderBlogItem = (item) => {
    if(item.type === "title") {
      return <center><h1 className="blogpost-title">{item.content}</h1></center>
    } else if (item.type === "subtitle") {
      return <h3 className="blogpost-subtitle">{item.content}</h3>
    } else if (item.type === "text") {
      return <p className="blogpost-text">{item.content}</p>
    }
  }

  useEffect(() => {
    import(`../blogs/${blogName}.json`)
      .then((data) => setJsonData(data.data))
      .catch((error) => console.error('Error loading JSON data:', error));
  }, [blogName]);

  return (<>
    {jsonData ? (
      <pre>
        <div className="blogbase-link">
          <Link to={location.state === null ? "/" : location.state.prevPath}>
            <BsArrowLeftShort size={50} style={{"display" : "fixed"}}/>
          </Link>
        </div>
        <div className="blogPost">
          {jsonData.map((item) => {
            return renderBlogItem(item);
          })}
        </div>
      </pre>
    ) : (
      <div className="blogPost">
        <p>Loading...</p>
      </div>
    )}
    </>)
}

export default BlogPostBase;