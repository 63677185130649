import React, { useState } from 'react'
import './CSS/ContactPage.css'
import axios from 'axios'
import { Config } from '../util/Config.js'
import ThankYou from '../components/ThankYou.js'


const ContactPage = () => {

  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [errorOnSubmit, setErrorOnSubmit] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    const content = {
      "name" : event.target.name.value,
      "email" : event.target.email.value,
      "subject" : event.target.subject.value,
      "content" : event.target.content.value,
    }
    
    axios.post(Config.EMAIL_URL, {
      "method": 'POST',
      "x-api-key": Config.EMAIL_API_KEY,
      "headers": {
        'Content-Type': 'application/json',
      },
      "body": content
    })
    .then((res) => {
      if(res.status === 200) {
        setEmailSubmitted(true);
      } else {
        setErrorOnSubmit(true);
      }
    });
  }

  if(!emailSubmitted) {
    return (
      <div className="contactPage">
        <div className="contact-title">
          <h1>Contact</h1>
          <br />
          <div style={{"width" : "80%", "margin" : "auto"}}>
          Please use the form below to contact me. Your information will be kept private. Thank you.
          </div>
        </div>
        <div className="form-container">
          <form onSubmit={handleSubmit}>
            <center>
              <input name="name" className="form-item" placeholder="Name..." required />
              <br />
              <input type="email" name="email" className="form-item" placeholder="Your Email..." />
            </center>
            <center><hr style={{"padding-bottom" : "1em", "width" : "90%"}}/></center>
            <input name="subject" className="form-item" placeholder="Subject..." required />
            <textarea name="content" className="form-item-textbox" placeholder="Content..." required />
            {errorOnSubmit ? <p style={{"color": "red"}}>There was an error processing your request. Please try again later.</p> : <></>}
            <center><a target="_blank" href="#" rel="noreferrer"><button type="submit" class="button-21">Submit Email</button></a></center>
          </form>
        </div>
      </div>
    )
  } else {
    return (<>
      <ThankYou />
    </>)
  }
}
// https://q7itwuhata.execute-api.us-east-1.amazonaws.com/prod/
export default ContactPage