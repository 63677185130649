import GolImage from '../images/game-of-life-loop.gif';
import ResImage from '../images/res.webp';
import ReactImage from '../images/react.webp'
import GUR from '../images/GUR.webp'
import UogImage from '../images/uog.webp'

export const projects = [
  {
    "title": "Game of Life", 
    "description": "A simulation developped by John Conway in 1970. For my final year of honours, I was tasked with creating a search algorithm to find 'spaceships'.", 
    "img" : GolImage, 
    "link" : "https://github.com/paulbeka/level-4-project", 
    "blog" : "gol",
  },
  {
    "title": "Res Group Project", 
    "description": "In my 3rd year of university, I worked on a team project involding RES-group, a sustainable energy company. I worked in a team of 4, and we created a react website using live API data to show customers the environmental impact of RES-group. For access to code contact me on the contact page.", 
    "img" : ResImage, 
    "blog" : "res"
  },
  {
    "title": "Personal Website", 
    "description": "A react/node.js project to display some of my work, thoughts, and experience. I made this site to share who I am and showcase my front-end skills. For access to code contact me on the contact page.", 
    "img" : ReactImage, 
    "blog" : "this_website"
  },
  {
    "title": "GU Rocketry", 
    "description": "A student-led socity, GUR launches recreational rockets to win compentitions and enhance student engagement with space. As part of the team, I had the chance to work with a lot of low-level code.", 
    "img" : GUR, 
    "link" : "https://github.com/paulbeka/GURocketry"
  },
  {
    "title": "Interesting University Projects", 
    "description": "In the blog post, I talk about some of my most interesting university projects that I worked on. These vary from Big Data to Web Apps.", 
    "img" : UogImage, 
    "blog" : "university_projects"
  }
];