import React, { useState, useEffect } from 'react';
import NavBar from '../components/NavBar';
import { Outlet, useLocation } from "react-router-dom";
import './CSS/PageCss.css';
import Hamburger from 'hamburger-react'
import {isMobile} from 'react-device-detect';
import MetaTags from '../util/MetaTags';


const BasePage = () => {

  const [displayBar, setDisplayBar] = useState(!isMobile);
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState('home');

  React.useEffect(() => {
    const path = location.pathname.replace('/', '');
    setCurrentPage(path === '' ? 'home' : path);
  }, [location.pathname]);

  const [pageTitle, setPageTitle] = useState('Paul Bekaert MSci in Computing Science from Uni of Glasgow');
  const [pageDescription, setPageDescription] = useState("Welcome to my professional website! I'm Paul Bekaert, and here you can explore my CV, skills, and past experience. Dive into my React-based portfolio to see my work in action and learn more about my qualifications.");

  useEffect(() => {

    if (location.pathname === '/projects') {
      setPageTitle('Paul Bekaert Projects');
      setPageDescription('Here you can find some of my most interesting projects, which each posed different types of challenges that needed to be overcome.');
    } else if (location.pathname === '/contact') {
      setPageTitle('Contact Paul Bekaert');
      setPageDescription('Please get in touch with me using this form. Your data will remain anonymous and I will aim to answer as fast as I can.');
    } else {
      setPageTitle('Paul Bekaert MSci in Computing Science from Uni of Glasgow');
      setPageDescription("Welcome to my professional website! I'm Paul Bekaert, and here you can explore my CV, skills, and past experience. Dive into my React-based portfolio to see my work in action and learn more about my qualifications.");
    }
  }, []);

  return (
    <div className="container-fluid main-container">
      <div className="row flex-nowrap">
        {displayBar && <NavBar setDisplay={setDisplayBar} currentPage={currentPage} />}
        <div className={displayBar ? "pageContentWithSidebar": "pageContentNoSidebar"} style={{"height" : "100%"}}>
          {isMobile ? 
          <div style={{"position":"fixed", "margin-top":"1em", "zIndex":"99"}}><Hamburger toggled={displayBar} toggle={setDisplayBar} aria-label="sidebar open button" /></div>:
          <></>}
          <MetaTags title={pageTitle} description={pageDescription} />
          
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default BasePage;