import React from 'react'
import './ThankYou.css'
import { Checkmark } from 'react-checkmark'


const ThankYou = () => {
  return (
    <div className="thankyoupage">
      <div className="thankstitle">
        <Checkmark size={200}/>
        <br />
        <h1>Thank you!</h1>
        <p>Your email has been sent. You may click off this page or go back to the main menu.</p>
      </div>
      
    </div>
  )
}

export default ThankYou